import moment from "moment";
import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";
import { handleDateFormat } from "utils/Utils";

export const ContrachapPostDetailed = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[
            marketingCreativeTemplateKeys.Contrachap
        ]?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];

    const startDateExists = textData?.start_date_time?.exists;
    const startDate = startDateExists && textData?.start_date_time?.date;
    const startTime = startDateExists && textData?.start_date_time?.time;
    const endDateExists = textData?.end_date_time?.exists;
    const endDate = endDateExists && textData?.end_date_time?.date;
    const endTime = endDateExists && textData?.end_date_time?.time;

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
        );
        loadFont(
            "https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap"
        );
    }, []);

    return (
        <div style={{ fontFamily: "Josefin Sans" }}>
            {textData?.heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "118px",
                        left: "91px",
                        fontWeight: 700,
                        fontSize: "64px",
                        width: "827px",
                        wordBreak: "break-word",
                        zIndex: 1,
                        textTransform: "uppercase",
                    }}
                >
                    {textData?.heading}
                </div>
            ) : null}
            {textData?.sub_heading ? (
                <div
                    style={{
                        position: "absolute",
                        width: "797px",
                        left: "91px",
                        top: "314px",
                        fontSize: "32px",
                        color: color2,
                        fontWeight: 500,
                        zIndex: 1,
                        paddingLeft: "23px",
                        paddingRight: "23px",
                        wordBreak: "break-word",
                    }}
                >
                    {textData?.sub_heading}
                </div>
            ) : null}
            {startDate ? (
                <div
                    style={{
                        position: "absolute",
                        top: "835px",
                        left: "91px",
                        color: color3,
                        fontWeight: 600,
                        fontFamily: "Montserrat",
                        fontSize: "36px",
                        zIndex: 2,
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        whiteSpace: "nowrap",
                    }}
                >
                    <div className="d-flex align-items-center">
                        <svg
                            width="38"
                            height="38"
                            style={{ marginRight: "20px" }}
                            viewBox="0 0 54 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 12.9999C0 10.5076 0 9.26145 0.535899 8.33325C0.886972 7.72517 1.39192 7.22022 2 6.86915C2.9282 6.33325 4.17436 6.33325 6.66667 6.33325H46.6667C49.159 6.33325 50.4051 6.33325 51.3333 6.86915C51.9414 7.22022 52.4464 7.72517 52.7974 8.33325C53.3333 9.26145 53.3333 10.5076 53.3333 12.9999C53.3333 15.4922 53.3333 16.7384 52.7974 17.6666C52.4464 18.2747 51.9414 18.7796 51.3333 19.1307C50.4051 19.6666 49.159 19.6666 46.6667 19.6666H6.66666C4.17436 19.6666 2.9282 19.6666 2 19.1307C1.39192 18.7796 0.886972 18.2747 0.535899 17.6666C0 16.7384 0 15.4922 0 12.9999Z"
                                fill={color3}
                            />
                            <path
                                d="M0 43.6668C0 47.438 0 49.3236 1.17157 50.4952C2.34315 51.6667 4.22876 51.6667 8 51.6667H45.3333C49.1046 51.6667 50.9902 51.6667 52.1618 50.4952C53.3333 49.3236 53.3333 47.438 53.3333 43.6667V33.0001C53.3333 29.2288 53.3333 27.3432 52.1618 26.1717C50.9902 25.0001 49.1046 25.0001 45.3333 25.0001H7.99999C4.22876 25.0001 2.34315 25.0001 1.17157 26.1717C0 27.3432 0 29.2288 0 33.0001V43.6668Z"
                                fill={color3}
                            />
                            <path
                                d="M13.332 1L13.332 9"
                                stroke={color3}
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                            <path
                                d="M40 1L40 9"
                                stroke={color3}
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                        <div>
                            {endDate
                                ? handleDateFormat(startDate, false, false) +
                                  " - " +
                                  handleDateFormat(endDate, false, false)
                                : null}
                            {!endDate
                                ? handleDateFormat(startDate, false)
                                : null}
                        </div>
                    </div>
                    {startTime ? (
                        <div className="d-flex align-items-center  mb-4">
                            <svg
                                style={{ marginRight: "20px" }}
                                width="38"
                                height="38"
                                viewBox="0 0 53 53"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="26.5"
                                    cy="26.5"
                                    r="23"
                                    stroke={color3}
                                    stroke-width="7"
                                />
                                <path
                                    d="M37.7277 27.0905H27.3413C27.2032 27.0905 27.0913 26.9786 27.0913 26.8405V17.636"
                                    stroke={color3}
                                    stroke-width="7"
                                    stroke-linecap="round"
                                />
                            </svg>
                            <div>
                                {startTime || null}
                                {endTime ? " - " + endTime : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
            {textData?.action_text ? (
                <div
                    style={{
                        position: "absolute",
                        left: "201.23px",
                        top: "670px",
                        padding: "28px 80px",
                        color: "black",
                        fontWeight: 500,
                        fontSize: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "uppercase",
                        zIndex: 1,
                        whiteSpace: "nowrap",
                    }}
                >
                    <div style={{ zIndex: 2 }}>{textData?.action_text}</div>
                    <div
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            backgroundColor: color2,
                            zIndex: 1,
                        }}
                    ></div>
                    <div
                        style={{
                            position: "absolute",
                            left: "-27px",
                            bottom: "-27px",
                            width: "100%",
                            height: "100%",
                            border: `2px solid ${color3}`,
                        }}
                    ></div>
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "405px",
                        height: "595px",
                        bottom: "0px",
                        right: "0px",
                        borderRadius: "34px 0px 0px 0px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                />
            ) : null}
            {textData?.details_list?.title ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "431px",
                        left: "18px",
                        fontWeight: 700,
                        fontSize: "36px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.details_list?.title}
                </div>
            ) : null}
            {textData?.details_list?.data ? (
                <div
                    style={{
                        position: "absolute",
                        color: color2,
                        top: "492px",
                        left: "18px",
                        fontWeight: 500,
                        fontSize: "32px",
                        zIndex: 1,
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.details_list?.data?.map((item) => (
                        <div>{item}</div>
                    ))}
                </div>
            ) : null}
            {textData?.additional_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "1014px",
                        left: "71px",
                        fontWeight: 500,
                        fontSize: "24px",
                        fontFamily: "Montserrat",
                        zIndex: 2,
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.additional_text}
                </div>
            ) : null}
            <svg
                width="161"
                height="85"
                viewBox="0 0 161 85"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                    position: "absolute",
                    top: "450px",
                    left: "888.28px",
                    zIndex: 1,
                }}
            >
                <path
                    d="M157 37.0666L131.552 7.00007L106.104 37.0666L80.656 7.00007L55.208 37.0666L29.7415 7.00007L4.27513 37.0666"
                    stroke={color2}
                    stroke-width="9"
                    stroke-miterlimit="10"
                />
                <path
                    d="M157 77.5479L131.552 47.4813L106.104 77.5479L80.656 47.4813L55.208 77.5479L29.7415 47.4813L4.27513 77.5479"
                    stroke={color2}
                    stroke-width="9"
                    stroke-miterlimit="10"
                />
            </svg>
            <svg
                width="161"
                height="85"
                style={{
                    position: "absolute",
                    top: "991px",
                    left: "599px",
                    zIndex: 1,
                }}
                viewBox="0 0 161 85"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M157 37.0666L131.552 7.00007L106.104 37.0666L80.656 7.00007L55.208 37.0666L29.7415 7.00007L4.27513 37.0666"
                    stroke={color2}
                    stroke-width="9"
                    stroke-miterlimit="10"
                />
                <path
                    d="M157 77.5479L131.552 47.4813L106.104 77.5479L80.656 47.4813L55.208 77.5479L29.7415 47.4813L4.27513 77.5479"
                    stroke={color2}
                    stroke-width="9"
                    stroke-miterlimit="10"
                />
            </svg>
            <svg
                width="1080"
                height="1080"
                viewBox="0 0 1080 1080"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_29_22)">
                    <rect width="1080" height="1080" fill={color1} />
                    <g opacity="0.7">
                        <path
                            d="M142 170H18V294H142V170Z"
                            stroke={color2}
                            stroke-width="2"
                            stroke-miterlimit="10"
                        />
                        <path
                            d="M203.996 108H79.9957V232H203.996V108Z"
                            fill={color2}
                        />
                    </g>
                    <path
                        d="M1125 239L45 1439H1125V239Z"
                        fill={color1}
                        style={{ filter: "brightness(0.8)" }}
                    />
                    <path d="M18 999H37.6845V1018.68L18 999Z" fill={color2} />
                    <path
                        d="M18 1038.5H37.6845V1058.2L18 1038.5Z"
                        fill={color2}
                    />
                    <path
                        d="M18 1018.81H37.6845V1038.5L18 1018.81Z"
                        fill={color2}
                    />
                    <path
                        d="M28 801.127V945.517"
                        stroke={color2}
                        stroke-width="6"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M61 783V945.517"
                        stroke={color2}
                        stroke-width="6"
                        stroke-miterlimit="10"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_29_22">
                        <rect width="1080" height="1080" fill={color2} />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
